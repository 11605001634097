import React from 'react';
import logo from './logo.svg';

function Home() {
  return (
    <div className="home">
      <h1>Welcome to Vertical Ventures</h1>
      <div className="bio">
        <img src={logo} alt="Vertical Ventures Logo" className="home-logo" />
        <h2>About Me</h2>
        <p>
          Hi, I'm Matt! I'm a passionate rock climber, rope access technician, and software developer.
          With over 15 years of climbing experience, I've tackled some of the most challenging routes
          and boulders around the world. When I'm not on the rocks, I'm either hanging from ropes
          doing industrial work or coding up new solutions to everyday problems.
        </p>
        <p>
          Vertical Ventures showcases my climbing achievements, rope access projects, and software
          development work. Feel free to explore and get in touch if you'd like to collaborate
          or just chat about climbing, rope work, or coding!
        </p>
      </div>
    </div>
  );
}

export default Home;