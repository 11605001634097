import React from 'react';

function Development() {
  const developmentProjects = [
    {
      title: "Climbing Route Tracker",
      description: "Developed a mobile app for climbers to log their routes, track progress, and share achievements with friends. Built using React Native and Firebase.",
      imageUrl: "heeltoecam.jpg",
      githubUrl: "https://github.com/username/climbing-route-tracker"
    },
    {
      title: "Rope Access Safety Checklist",
      description: "Created a web application for rope access technicians to perform digital safety checks before starting work. Includes user authentication and data persistence.",
      imageUrl: "belaying.jpg",
      githubUrl: "https://github.com/username/rope-access-checklist"
    },
    {
      title: "Personal Portfolio Website",
      description: "Designed and built this responsive website to showcase my climbing, rope access, and development projects. Utilized React and modern CSS techniques.",
      imageUrl: "cheesecakehigh.jpg",
      githubUrl: "https://github.com/username/portfolio-website"
    }
  ];

  return (
    <div className="development">
      <h1>Software Development Projects</h1>
      <p>As a software developer, I've worked on various projects, often combining my passion for climbing and rope access with my coding skills. Here are some of my recent projects:</p>
      
      <div className="development-projects">
        {developmentProjects.map((project, index) => (
          <div key={index} className="project-card">
            <h3>{project.title}</h3>
            <div className="image-placeholder" style={{backgroundImage: `url(${project.imageUrl})`}}></div>
            <p>{project.description}</p>
            <a href={project.githubUrl} target="_blank" rel="noopener noreferrer">View on GitHub</a>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Development;